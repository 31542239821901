<template lang="pug">
  .modal
    //- Инпуты расширенного поиска
    form(@submit.prevent).search.search--modal
      .search__inputs
        .form-el.custom-el
          .form-el__title Муниципальный район/округ
          .form-el__body
            el-autocomplete(
              v-model="district"
              :fetch-suggestions="querySearchDistrict"
              value-key="short_name"
              placeholder="Выберите район"
              @select="handleSelectDistrict"
              :disabled="districtDisabled"
              autocomplete="new-password"
              class="custom-el"
            )
        .form-el.custom-el
          .form-el__title Поселение
          .form-el__body
            el-autocomplete(
              v-model="settlement"
              :fetch-suggestions="querySearchSettlement"
              value-key="short_name"
              placeholder="Выберите поселение"
              @select="handleSelectSettlement"
              :disabled="settlementDisabled"
              autocomplete="new-password"
              class="custom-el"
              popper-class="custom-autocomplete"
            )
        .form-el.custom-el
          .form-el__title Город
          .form-el__body
            el-autocomplete(
              v-model="city"
              :fetch-suggestions="querySearchCity"
              value-key="short_name"
              placeholder="Выберите город"
              @select="handleSelectCity"
              :disabled="cityDisabled"
              autocomplete="new-password"
              class="custom-el"
            )
        .form-el.custom-el
          .form-el__title Населенный пункт
          .form-el__body
            el-autocomplete(
              v-model="locality"
              :fetch-suggestions="querySearchLocality"
              value-key="short_name"
              placeholder="Выберите населенный пункт"
              @select="handleSelectLocality"
              :disabled="localityDisabled"
              autocomplete="new-password"
              class="custom-el"
            )
        .form-el.custom-el
          .form-el__title Элемент планировочной структуры
          .form-el__body
            el-autocomplete(
              v-model="planningStructure"
              :fetch-suggestions="querySearchPlanningStructure"
              value-key="short_name"
              placeholder="Выберите элемент планировочной структуры"
              @select="handleSelectPlanningStructure"
              :disabled="planningStructureDisabled"
              autocomplete="new-password"
              class="custom-el"
            )
        .form-el.custom-el
          .form-el__title Элемент улично-дорожной сети
          .form-el__body
            el-autocomplete(
              v-model="roadElement"
              :fetch-suggestions="querySearchRoadElement"
              value-key="short_name"
              placeholder="Выберите элемент улично-дорожной сети"
              @select="handleSelectRoadElement"
              :disabled="roadElementDisabled"
              autocomplete="new-password"
              class="custom-el"
            )
        .form-el.custom-el
          .form-el__title Номер здания/сооружения
          .form-el__body
            el-input(
              v-model="buildingNumber"
              placeholder="Выберите номер здания/сооружения"
              :disabled="buildingNumberDisabled"
              autocomplete="new-password"
            )
        .form-el.custom-el
          .form-el__title Номер помещения
          .form-el__body
            el-input(
              v-model="premisesNumber"
              placeholder="Выберите номер помещения"
              :disabled="premisesNumberDisabled"
              autocomplete="new-password"
            )
        .form-el.custom-el
          .form-el__title Номер комнаты в пределах помещения
          .form-el__body
            el-input(
              v-model="roomNumber"
              placeholder="Выберите комнату"
              :disabled="roomNumberDisabled"
              autocomplete="new-password"
            )
        .form-el.custom-el
          .form-el__title Почтовый индекс
          .form-el__body
            el-input(
              v-model="postalCode"
              placeholder="Введите почтовый индекс"
              :disabled="postalCodeDisabled"
              autocomplete="new-password"
            )
        .form-el.custom-el
          .form-el__title Кадастровый номер
          .form-el__body
            el-input(
              v-model="cadastralNumber"
              placeholder="Введите кадастровый номер"
              :disabled="cadastralNumberDisabled"
              autocomplete="new-password"
            )
      .search__btn-group
          button.search__btn.search__btn--auto.btn.btn--transparent(
            @click="handleReset()"
            type="button"
          ) Очистить
          button.search__btn.btn.btn--main(
            @click.prevent="submitForm()"
            :class="{ 'btn--update': loadHouseData }"
            type="submit"
            :disabled="submitBtnDisabled"
          )
            svg.spinner(v-if="loadHouseData" viewBox="0 0 50 50")
              circle.path(cx="25" cy="25" r="20" fill="none" stroke-width="5")
            span(v-else) Добавить
</template>

<script>
export default {
  name: 'ModalAdd',

  data() {
    return {
      title: 'Поиск объекта',
      loadHouseData: false,

      // инпуты расширенного поиска
      district: '',
      districtID: '',
      districtDisabled: false,
      settlement: '',
      settlementID: '',
      settlementDisabled: true,
      city: '',
      cityID: '',
      cityDisabled: true,
      locality: '',
      localityID: '',
      localityDisabled: true,
      planningStructure: '',
      planningStructureID: '',
      planningStructureDisabled: true,
      roadElement: '',
      roadElementID: '',
      roadElementDisabled: true,
      buildingNumber: '',
      buildingNumberID: '',
      buildingNumberDisabled: true,
      premisesNumber: '',
      premisesNumberID: '',
      premisesNumberDisabled: true,
      roomNumber: '',
      roomNumberID: '',
      roomNumberDisabled: true,
      postalCode: '',
      postalCodeDisabled: false,
      cadastralNumber: '',
      cadastralNumberDisabled: false,
      submitBtnDisabled: true
    }
  },

  watch: {
    buildingNumber() {
      if (this.buildingNumber.length) {
        this.submitBtnDisabled = false
        this.premisesNumberDisabled = false
      } else {
        this.premisesNumber = ''
        this.submitBtnDisabled = true
        this.premisesNumberDisabled = true
      }
    },

    premisesNumber() {
      if (this.premisesNumber.length) {
        this.roomNumberDisabled = false
      } else {
        this.roomNumber = ''
        this.roomNumberDisabled = true
      }
    }
  },

  methods: {
    querySearchDistrict(queryString, cb) {
      this.$store.dispatch("advancedSearch/getDistrictList", { name: this.district }, queryString).then((data) => {
        (data.length === 0) ? cb([]) : cb(data);
      })
    },
    querySearchSettlement(queryString, cb) {
      this.$store.dispatch("advancedSearch/getSettlementList", { name: this.settlement, mun_district: this.districtID}, queryString).then((data) => {
        (data.length === 0) ? cb([]) : cb(data);
      })
    },
    querySearchCity(queryString, cb) {
      this.$store.dispatch("advancedSearch/getCityList", { name: this.city, settlement: this.settlementID }, queryString).then((data) => {
        if (data.length !== 0) {
          cb(data)
        } else {
          this.$store.dispatch("advancedSearch/getCityList", { name: this.city, mun_district: this.districtID }, queryString).then((data) => {
            (data.length === 0) ? cb([]) : cb(data); 
          })
        }
      })
      if (this.district.indexOf("городской округ город") != -1) {
        this.$store.dispatch("advancedSearch/getCityList", { name: this.city, mun_district: this.districtID }, queryString).then((data) => {
          (data.length === 0) ? cb([]) : cb(data); 
        })
      }
    },
    querySearchLocality(queryString, cb) {
      this.$store.dispatch("advancedSearch/getLocalityList", { name: this.locality, mun_district: this.districtID, settlement: this.settlementID }, queryString).then((data) => {
        if (data.length !== 0) {
          cb(data);
        } else {
          this.$store.dispatch("advancedSearch/getLocalityList", { name: this.locality, settlement: this.settlementID }, queryString).then((data) => {
            (data.length === 0) ? cb([]) : cb(data); 
          })
        }
      })
    },
    querySearchPlanningStructure(queryString, cb) {
      if (this.district.indexOf("городской округ город") != -1) {
        this.$store.dispatch("advancedSearch/getPlanningStructureList", { name: this.planningStructure, city: this.cityID, locality: this.localityID, settlement: this.districtID }, queryString).then((data) => {
          (data.length === 0) ? cb([]) : cb(data);
        })
      }
      this.$store.dispatch("advancedSearch/getPlanningStructureList", { name: this.planningStructure, city: this.cityID, locality: this.localityID, settlement: this.settlementID }, queryString).then((data) => {
        (data.length === 0) ? cb([]) : cb(data);
      })
    },
    querySearchRoadElement(queryString, cb) {
      this.$store.dispatch("advancedSearch/getRoadElementList", { name: this.roadElement, plan_structure: this.planningStructureID, settlement: this.cityID }, queryString).then((data) => {
        if (data.length !== 0) {
          cb(data);
        } else {
          this.$store.dispatch("advancedSearch/getRoadElementList", { name: this.roadElement, plan_structure: this.planningStructureID, settlement: this.localityID }, queryString).then((data) => {
            (data.length === 0) ? cb([]) : cb(data); 
          })
        }
      })
    },
    
    handleSelectDistrict(item) {
      if (item.objectid) {
        this.districtID = item.objectid
        this.settlement = ''
        this.settlementID = ''
        this.settlementDisabled = false
        this.city = ''
        this.cityID = ''
        this.cityDisabled = true
        this.locality = ''
        this.localityID = ''
        this.localityDisabled = true
        this.planningStructure = ''
        this.planningStructureID = ''
        this.planningStructureDisabled = true
        this.roadElement = ''
        this.roadElementID = ''
        this.roadElementDisabled = true
        this.buildingNumber = ''
        this.buildingNumberID = ''
        this.buildingNumberDisabled = true
        this.premisesNumber = ''
        this.premisesNumberID = ''
        this.premisesNumberDisabled = true
        this.roomNumber = ''
        this.roomNumberID = ''
        this.roomNumberDisabled = true
      }
      if (item.short_name.indexOf("городской округ город") != -1) {
        this.$store.dispatch("advancedSearch/getCityList", { name: this.city, mun_district: this.districtID }).then((data) => {
          (data.length === 0) ? this.cityDisabled = true : this.cityDisabled = false
        })
        this.$store.dispatch("advancedSearch/getLocalityList", { name: this.locality, mun_district: this.districtID }).then((data) => {
          (data.length === 0) ? this.localityDisabled = true : this.localityDisabled = false
        })
        this.$store.dispatch("advancedSearch/getPlanningStructureList", { name: this.locality, city: this.districtID }).then((data) => {
          (data.length === 0) ? this.planningStructureDisabled = true : this.planningStructureDisabled = false
        })
        this.settlementDisabled = true
      }
    },
    handleSelectSettlement(item) {
      console.log(item)
      if (item.objectid) {
        this.settlementID = item.objectid
        this.$store.dispatch("advancedSearch/getCityList", { name: this.city, mun_district: this.districtID }).then((data) => {
          if (data.length !== 0) {
            this.cityDisabled = false
          } else {
            this.$store.dispatch("advancedSearch/getLocalityList", { name: this.locality, settlement: this.settlementID }).then((data) => {
              (data.length === 0) ? this.localityDisabled = true : this.localityDisabled = false
            })
          }
        })
        this.city = ''
        this.cityID = ''
        this.cityDisabled = true
        this.locality = ''
        this.localityID = ''
        this.localityDisabled = false
        this.planningStructure = ''
        this.planningStructureID = ''
        this.planningStructureDisabled = true
        this.roadElement = ''
        this.roadElementID = ''
        this.roadElementDisabled = true
        this.buildingNumber = ''
        this.buildingNumberID = ''
        this.buildingNumberDisabled = true
        this.premisesNumber = ''
        this.premisesNumberID = ''
        this.premisesNumberDisabled = true
        this.roomNumber = ''
        this.roomNumberID = ''
        this.roomNumberDisabled = true
      }
      if (item.short_name.indexOf("городское поселение город") != -1) {
        this.cityDisabled = false
      }
      if (item.short_name.indexOf("сельское поселение село") != -1) {
        this.planningStructureDisabled = false
      }
    },
    handleSelectCity(item) {
      if (item.objectid) {
        this.cityID = item.objectid
        this.$store.dispatch("advancedSearch/getPlanningStructureList", { name: this.planningStructure, city: this.cityID, locality: this.localityID, settlement: this.settlementID }).then((data) => {
          if (data.length !== 0) {
            this.planningStructureDisabled = false
          } 
          this.$store.dispatch("advancedSearch/getRoadElementList", { name: this.roadElement, plan_structure: this.planningStructureID, settlement: this.cityID }).then((data) => {
            if (data.length !== 0) {
              this.roadElementDisabled = false
            } else {
              this.$store.dispatch("advancedSearch/getBuildingNumberList", { name: this.buildingNumber, street: this.roadElementID, locality: this.localityID }).then((data) => {
                (data.length === 0) ? this.buildingNumberDisabled = true : this.buildingNumberDisabled = false
              })
            }
          })
        })
        this.localityDisabled = true
        this.planningStructure = ''
        this.planningStructureID = ''
        this.roadElement = ''
        this.roadElementID = ''
        this.buildingNumber = ''
        this.buildingNumberID = ''
        this.premisesNumber = ''
        this.premisesNumberID = ''
        this.premisesNumberDisabled = true
        this.roomNumber = ''
        this.roomNumberID = ''
        this.roomNumberDisabled = true
      }
    },
    handleSelectLocality(item) {
      if (item.objectid) {
        this.localityID = item.objectid
        this.$store.dispatch("advancedSearch/getPlanningStructureList", { name: this.planningStructure, city: this.cityID, locality: this.localityID, settlement: this.settlementID }).then((data) => {
          if (data.length !== 0) {
            this.planningStructureDisabled = false
          } 
          this.$store.dispatch("advancedSearch/getRoadElementList", { name: this.roadElement, plan_structure: this.planningStructureID, settlement: this.localityID }).then((data) => {
            if (data.length !== 0) {
              this.roadElementDisabled = false
            } else {
              this.roadElementDisabled = true
            }
          })
        })
        this.cityDisabled = true
        this.planningStructure = ''
        this.planningStructureID = ''
        this.roadElement = ''
        this.roadElementID = ''
        this.buildingNumber = ''
        this.buildingNumberID = ''
        this.buildingNumberDisabled = false
        this.premisesNumber = ''
        this.premisesNumberID = ''
        this.premisesNumberDisabled = true
        this.roomNumber = ''
        this.roomNumberID = ''
        this.roomNumberDisabled = true
      }
    },
    handleSelectPlanningStructure(item) {
      if (item.objectid) {
        this.planningStructureID = item.objectid
        this.$store.dispatch("advancedSearch/getRoadElementList", { name: this.roadElement, plan_structure: this.planningStructureID, settlement: this.localityID }).then((data) => {
          if (data.length !== 0) {
            this.roadElementDisabled = false
          } else {
            this.$store.dispatch("advancedSearch/getBuildingNumberList", { name: this.buildingNumber, street: this.roadElementID, locality: this.localityID }).then((data) => {
              (data.length === 0) ? this.buildingNumberDisabled = true : this.buildingNumberDisabled = false
            })
          }
        })
        if (!this.city && !this.locality) {
          this.cityDisabled = true
          this.localityDisabled = true
        }
        this.roadElement = ''
        this.roadElementID = ''
        this.buildingNumber = ''
        this.buildingNumberID = ''
        this.premisesNumber = ''
        this.premisesNumberID = ''
        this.premisesNumberDisabled = true
        this.roomNumber = ''
        this.roomNumberID = ''
        this.roomNumberDisabled = true
      }
    },
    handleSelectRoadElement(item) {
      if (item.objectid) {
        this.roadElementID = item.objectid
        this.$store.dispatch("advancedSearch/getRoadElementList", {street: this.roadElementID})
        this.buildingNumber = ''
        this.buildingNumberID = ''
        this.buildingNumberDisabled = false
        this.premisesNumber = ''
        this.premisesNumberID = ''
        this.premisesNumberDisabled = true
        this.roomNumber = ''
        this.roomNumberID = ''
        this.roomNumberDisabled = true
      }
    },
    handleReset() {
      this.district = ''
      this.districtID = ''
      this.districtDisabled = false
      this.settlement = ''
      this.settlementID = ''
      this.settlementDisabled = true
      this.city = ''
      this.cityID = ''
      this.cityDisabled = true
      this.locality = ''
      this.localityID = ''
      this.localityDisabled = true
      this.planningStructure = ''
      this.planningStructureID = ''
      this.planningStructureDisabled = true
      this.roadElement = ''
      this.roadElementID = ''
      this.roadElementDisabled = true
      this.buildingNumber = ''
      this.buildingNumberID = ''
      this.buildingNumberDisabled = true
      this.premisesNumber = ''
      this.premisesNumberID = ''
      this.premisesNumberDisabled = true
      this.roomNumber = ''
      this.roomNumberID = ''
      this.roomNumberDisabled = true
      this.postalCode = ''
      this.postalCodeDisabled = false
      this.cadastralNumber = ''
      this.cadastralNumberDisabled = false
    },

    async submitForm() {
      const data_ = {
        house_params: []
      }
      if (this.localityID) {
        data_['locality'] = this.localityID
      }
      if (this.planningStructureID) {
        data_['plan_structure'] = this.planningStructureID
      }
      if (this.roadElementID) {
        data_['street'] = this.roadElementID
      }
      if (this.buildingNumber) {
        data_['housenum'] = this.buildingNumber
      }
      if (this.postalCode) {
        data_.house_params.push({
          typeid: 5,
          value: this.postalCode
        })
      }
      if (this.cadastralNumber) {
        data_.house_params.push({
          typeid: 8,
          value: this.cadastralNumber
        })
      }
      // todo Пока не используются
      // if (this.premisesNumber) {
      //   data_['apart'] = this.premisesNumber
      // }
      // if (this.roomNumber) {
      //   data_['stead'] = this.roomNumber
      // }
      try {
        this.loadHouseData = true
        await this.$store.dispatch("addHouse/addHouse", data_)
        this.$emit('completeDownload', this.buildingNumber)
        this.handleReset()
      } catch (error) {
        this.loadHouseData = false
        this.$emit('errorDownload', error)
      }
      this.loadHouseData = false
    }
  }
}
</script>