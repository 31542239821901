<template lang="pug">
  .fb
    .fb__container.container

      //- Титульная линия страницы
      .fb__top-line
        .fb__title-container
          h1.fb__title {{ title }}
        .fb__btn-container(v-if="isLogin")
          a-form-el(
            v-bind="formFields.select"
            type="select"
            @on-change="changeValue"
          )
          button.fb__btn.btn.btn--main(
            :class="{ 'btn--update': isBtnDisabled }"
            @click="loadUpdate()"
            :disabled="isBtnDisabled"
          ) 
            svg.spinner(v-if="isBtnDisabled" viewBox="0 0 50 50")
              circle.path(cx="25" cy="25" r="20" fill="none" stroke-width="5")
            span {{ isBtnDisabled ? 'Идет загрузка' : 'Синхронизация с ГАР БД ФИАС' }}

      .fb__content
        .fb__btn-container(v-if="isLogin")
          button.fb__btn.btn.btn--main(
            @click="isModalShow = !isModalShow"
            :class="{ 'btn--update': isBtnDisabled }"
            :disabled="isBtnDisabled"
          ) Добавить дом

    //- Загрузка данных
    .home__upload(v-if="isLogin")
      .container
        .home__upload-container.upload
          h3.upload__title Загрузить данные из файла
          p.upload__subtitle Данная функция позволяет добавить новые адреса в базуадресов ГАР КО с помощью специального файла.

          AttachFile(
            :isBtnDisabled="isBtnDisabled"
            @loadBegin="loadBegin"
          )

          template(v-if="isBtnDisabled")
            .upload__status
              p.upload__message Статус: {{ socket_message[socket_message.length - 1] || 'загрузка...' }}
              .upload__loader
            button.upload__btn.btn.btn--icon(
              v-show="socket_message.length > 1"
              @click="showDetail = !showDetail"
            )
              span Показать подробности
              svg(width="20" height="20")
                use(xlink:href="@/assets/images/sprite/sprite.svg#icon-arrow-down")
            el-collapse-transition
              div.upload__detail(v-show="showDetail")
                ul.upload__detail-list
                  li.upload__detail-item(v-if="filteredSocketMessages.length === 0")
                    p.upload__detail-item-text загрузка...
                    .upload__loader
                  li.upload__detail-item(v-else v-for="item in filteredSocketMessages")
                    p.upload__detail-item-text {{ item }}
                    .upload__loader
    el-dialog(
      :visible.sync="isModalShow"
      title="Добавление дома"
      center
    )
      ModalAdd(
        @completeDownload="completeDownload"
        @errorDownload="errorDownload"
      )
</template>

<script>
import AttachFile from '../../../components/attach-file/attach-file'
import AFormEl from '../../../components/a-form-el/a-form-el'
import ModalAdd from './components/ModalAdd'
import { websocketUrl } from '../../../config'

export default {
  name: 'Updates',

  components: {
    AttachFile,
    AFormEl,
    ModalAdd
  },

  data() {
    return {
      title: 'Обновления',
      lastUpdate: '09.10.2021',
      isModalShow: false,
      socket: null,
      socket_message: [],
      message: '',
      isBtnDisabled: true,
      showDetail: false,
      selected: '',
      button_name: 'Синхронизация с ГАР БД ФИАС',
      select_object: undefined,
      formFields: {
        select: {
          fieldName: "label",
          type: "select",
          value: "",
          placeholder: "Выберите файл",
          options: [],
          custom: true
        }
      },
    }
  },

  computed: {
    isLogin() {
      return this.$store.getters['auth/isAuthenticated']
    },
    filteredSocketMessages() {
      return this.socket_message.filter(item => item);
    },
    lastDate() {
      return this.$store.getters['statistics/lastDate']
    }
  },

  watch: {
    message(value) {
      if (value === 'connection is clear') {
        this.isBtnDisabled = false
      } else if (value === 'Парсинг файлов закончен') {
        this.isBtnDisabled = false
        this.$notify({
          title: 'Success',
          message: 'Загрузка завершена',
          position: 'bottom-right',
          type: 'success',
          duration: 0,
          customClass: 'custom-notification'
        });
      } else {
        this.isBtnDisabled = true  
      }
    }
  },

  methods: {
    loadBegin() {
      this.message = 'загрузка началась'
    },
    loadUpdate() {
      if (this.select_object.value !== undefined) {
        this.isBtnDisabled = true;
      }
      this.$store.dispatch('update/sendUpdate', {id: this.select_object.value}).then(() => {
        this.isBtnDisabled = false;
      })
    },
    changeValue(field, value) {
      let selected = this.formFields.select.options.find((i) => {
        return i.value === value
      })
      this.formFields.select.value = selected.label
      this.select_object = selected
    },
    sendSocket() {
      this.socket.send(this.message);
      this.message = ''
    },
    connect() {
      this.socket = new WebSocket(websocketUrl);
      this.socket.onmessage = this.handleOnMessage;

      this.socket.onclose = () => {
        setTimeout(() => {
          this.connect();
        }, 1000)
      }
    },
    handleOnMessage({ data }) {
      const { message } = JSON.parse(data);

      if (message?.status) {
        this.message = message?.status
        this.socket_message = this.socket_message.concat(message?.status);
      } else if (message?.conn_status) {
        this.message = message?.conn_status;
        this.socket_message = this.socket_message.concat(message?.status);
        console.log(this.filteredSocketMessages)
      } else {
        this.message = message.message;
        this.socket_message = this.socket_message.concat(message.message);
      }
    },
    completeDownload(house) {
      this.isModalShow = false
      this.$notify({
        title: 'Success',
        message: `Дом №${house} успешно добавлен в реестр`,
        position: 'top-right',
        type: 'success',
        duration: 4000,
        customClass: 'custom-notification'
      });
    },

    errorDownload() {
      this.$notify({
        title: 'Error',
        message: 'Такой дом уже существует в системе',
        position: 'top-right',
        type: 'error',
        duration: 4000,
        customClass: 'custom-notification-error'
      });
    }
  },

  created() {
    this.connect();
  },
  
  mounted() {
    this.$store.dispatch('update/getListUpdate').then((data) => {
      let data_ = []
      data.forEach((i) => {
        data_.push({
          value: i.id,
          label: "Обновление от: " + i.date
        })
      })
      this.formFields.select.options = data_
    })
  }
}
</script>

<style lang="scss">
.custom-notification {
  width: 100%;
  max-width: 586px;
  background-color: #e0fbde;
  border: 1px solid #97d794;
  border-left: 3px solid #02f902;
  border-radius: 0;

  .el-notification__group {
    display: flex;
    align-items: center;
  }

  .el-notification__content {
    margin: 0;
  }

  .el-notification__title {
    margin-right: 8px;
  }
}

.custom-notification-error {
  width: 100%;
  max-width: 586px;
  background-color: #fbdede;
  border: 1px solid #d79494;
  border-left: 3px solid #f90202;
  border-radius: 0;

  .el-notification__group {
    display: flex;
    align-items: center;
  }

  .el-notification__content {
    margin: 0;
  }

  .el-notification__title {
    margin-right: 8px;
  }
}
</style>