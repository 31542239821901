<template lang="pug">
  .attach-file
    div.attach-file__wrapper
      input(
        name="file"
        type="file"
        id="input__file"
        :disabled="pending"
        @change="onAddFile"
      ).attach-file__file
      label(
        for="input__file"
      ).attach-file__file-button
        span.attach-file__file-button-text {{ pending ? 'Файл готов к загрузке на сервер' : 'Выберите файл' }}
    p.attach-file__description Расширение файла .zip
    button.attach-file__btn.btn.btn--main(
      :class="{ 'btn--update': isBtnDisabled }"
      :disabled="isBtnDisabled"
      @click="onUploadFile"
    )
      svg.spinner(v-if="isBtnDisabled" viewBox="0 0 50 50")
        circle.path(cx="25" cy="25" r="20" fill="none" stroke-width="5")
      span {{ isBtnDisabled ? 'Идет загрузка' : 'Загрузка' }}

</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "attach-file",

  props: {
    isBtnDisabled: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      button_name: "Загрузить",
      uploadFile: null,
      pending: false
    }
  },

  methods: {
    ...mapActions(["postMethod"]),
    onAddFile(e) {
      const file = e.target.files[0];
      this.uploadFile = new FormData();
      this.uploadFile.append("zip_file", file);
      this.pending = true;
    },
    onUploadFile() {
      if (this.pending) {
        this.$emit('loadBegin')
        this.isBtnDisabled = true
        this.$notify({
          title: 'Loading',
          message: 'Загрузка началась',
          position: 'bottom-right',
          type: 'success',
          customClass: 'custom-notification'
        });
      }
      this.postMethod({data: this.uploadFile}).then(() => {
        this.isBtnDisabled = false
        this.pending = false
      })
    }
  },
};
</script>
